<template>
  <div id="dragDrop" class="d-flex-content-center top-index-ui">
    <div id="dropContainer" class="frosted-glass p-4">
      <div class="container text-left">
        <div class="row mt-2 p-4">
          <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-left">
            <h2>Add data to Scout</h2>
          </div>
        </div>

        <div id="dataOptions" class="row p-4" v-if="!mobileMode">
          <div
            id="uploadData"
            class="col-12 col-lg-6 col-md-12 col-sm-12 d-flex flex-row"
          >
            <div
              class="title mr-3 hover-highlight cursor-pointer"
              :class="{ active: btn.active === true }"
              v-for="(btn, i) in dataOptions"
              :key="i"
            >
              {{ btn.name }}
            </div>
          </div>
        </div>

        <div class="row pl-4 pr-4">
          <div class="col-6">
            <p>
              {{ description }}
            </p>
          </div>
        </div>

        <div id="sampleDesigns" v-show="sampleDesigns" class="p-4">
          <div class="row mt-3">
            <div
              class="col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-start p-3"
              v-for="(project, index) in projectLinks"
              :key="index"
            >
              <b-img
                class="static cursor-pointer"
                :src="project.image"
                :alt="project.name + ' project in Scout'"
                fluid
                @click="loadSample(project.link)"
              ></b-img>
              <div class="mt-4"></div>
              <p>{{ project.description }}</p>
            </div>
          </div>
        </div>

        <div class v-if="!mobileMode && sampleDesigns === false">
          <div class="row mt-2 p-4">
            <div class="col-12 col-lg-10 col-md-12 col-sm-12 d-flex flex-wrap">
              <uploadFolder
                v-for="(folder, i) in uploadFolders"
                :key="'upload-folder' + i"
                :folder="folder"
                @dataUpload="dataUpload"
              />
            </div>
          </div>
          <div class="row mt-2 p-4">
            <b-button
              type="submit"
              variant="primary"
              class="primary-button ml-2 flex-grow-1"
              @click="submit"
              :disabled="unlockSubmit === false"
              >start scout</b-button
            >
          </div>
          <div class="row">
            <div class="col-10 mx-auto">
              <div class="units-text">
                *scout is a client-side application with no server backend. Data
                lives only on your machine/browser. No information or data is
                sent to any server. If you refresh the browser window, all
                information will be lost.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadFolder from '@/components/dragDrop/uploadFolder.vue'

import { uploadFolders, customData } from '@/assets/js/library.js'

import {
  createInputObject,
  createMetricObject
} from '@/assets/js/dataobjects.js'

import { mapGetters } from 'vuex'

let uploadData = {
  'settings.json': null,
  'data.csv': null,
  'context.json': null
}

export default {
  components: {
    uploadFolder
    // arrowIcon
  },
  data() {
    return {
      dataOptions: [
        { name: 'upload data', active: true, link: 'upload', mobile: false },
        { name: 'try a sample', active: false, link: 'sample', mobile: true }
      ],
      uploadFolders: uploadFolders,
      unlockSubmit: false,
      sampleDesigns: false,
      projectLinks: [
        {
          link: 'DemoComp',
          name: 'DemoComp',
          description: 'Example computational design space.',
          image: require('@/assets/images/scout_dice_screenshot.png')
        },
        {
          link: 'hangzhou',
          name: 'Hangzhou',
          description:
            'Discover hundreds of “optimal” solutions that vary with block size.',
          image: require('@/assets/images/hangzhou_screen.png')
        },
        {
          link: 'hawaii',
          name: 'Hawaiʻi',
          description: 'Explore the future of housing development in Hawai’i.',
          image: require('@/assets/images/hawaii_screen.png')
        },
        {
          link: 'tampa',
          name: 'Tampa',
          description:
            'Visualize the trade-offs among daylight, views, and efficiency.',
          image: require('@/assets/images/tampa_screen.png')
        }
      ]
    }
  },
  created() {
    if (this.mobileMode) this.sampleDesigns = true
  },
  computed: {
    ...mapGetters({
      mobileMode: 'getMobileMode'
    }),
    dataOptionsCompute() {
      return this.dataOptions.filter(e => {
        if (this.mobileMode) {
          return e.mobile === true
        } else {
          return e
        }
      })
    },
    description() {
      return this.dataOptions[0]['active'] === true
        ? 'Drag a drop the required file(s) to start Scout.'
        : 'Select from the options below to load Scout.'
    }
  },
  methods: {
    toggleDataButton(option) {
      this.dataOptions.forEach(e => (e.active = false))

      switch (option.link) {
        case 'upload':
          this.sampleDesigns = false
          option.active = true
          break

        case 'sample':
          this.sampleDesigns = true
          option.active = true
          break
      }
    },
    loadSample(projectName) {
      this.$store.dispatch('readModelData', { project: projectName })

      this.$store.commit('setScoutMode', 'custom')
      this.$store.commit('setLoadingFlag', true)
      // this.$emit('setControlsFlag', true)
      this.$store.commit('setExplorePanel', ['Controls', true])
      this.$emit('setDragDropFlag', false)
    },
    dataUpload(uploadFolder, data) {
      if (uploadFolder === 'models') {
        customData[data['metadata']['ID']] = data
      } else {
        uploadData[uploadFolder] = data
      }

      if (
        Object.values(uploadData).every(e => e !== null) &&
        Object.keys(customData).length > 0
      ) {
        this.unlockSubmit = true
      }
    },
    submit(e) {
      const settingsData = uploadData['settings.json']
      const modelData = uploadData['data.csv']

      const inputSettings = settingsData['inputInfo']
      const metricSettings = settingsData['metricInfo']

      const [
        inputStatus,
        inputMessage,
        inputHeaders,
        inputObject
      ] = createInputObject(modelData, inputSettings, '0')
      const [
        metricStatus,
        metricMessage,
        metricHeaders,
        metricObject
      ] = createMetricObject(modelData, metricSettings)

      if (!inputStatus) {
        this.$store.commit('setNotificationObject', {
          flag: true,
          message: inputMessage,
          type: 'error'
        })

        throw new Error('input headers are incorrect')
      }

      if (!metricStatus) {
        this.$store.commit('setNotificationObject', {
          flag: true,
          message: metricMessage,
          type: 'error'
        })

        throw new Error('metric headers are incorrect')
      }

      let iterationArray = modelData.map(d => d['iteration'])

      this.$store.commit(
        'setContextObject',
        Object.assign(uploadData['context.json'], { _isVue: true })
      )
      this.$store.commit('setProjectScale', 1)
      this.$store.commit('setInputObject', inputObject)
      // commit metric data to store
      this.$store.commit('setMetricObject', Object.assign(metricObject), {
        _isVue: true
      })
      // // commit changes to store
      this.$store.commit('setInputHeaders', inputHeaders)
      this.$store.commit('setMetricHeaders', metricHeaders)
      // get last iteration ID
      this.$store.commit('setNumberOfModels', iterationArray)
      // set model data
      this.$store.commit('setModelData', modelData)
      // set model data loaded flag
      this.$store.commit('setModelDataFlag', true)
      // set settings loaded flag
      this.$store.commit('setSettings', metricSettings)

      this.$emit('setDragDropFlag', false)
      // this.$emit('setControlsFlag', true)
      this.$store.commit('setExplorePanel', ['Controls', true])
    }
  }
}
</script>

<style lang="scss">
#dragDrop {
  position: absolute;
  top: 0;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#dropContainer {
  max-height: 700px;
  overflow-y: auto;

  .title {
    &.active {
      border-bottom: 1px solid $highlight-color;
    }
  }
  img {
    border: 1px solid transparent;
    &:hover {
      border: 1px solid $off-white;
    }
  }
}
</style>
